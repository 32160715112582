.nexter-input {
  height: 48px;
  padding: 0.5rem;
  background-image: none !important;
  background-color: $white;
  border-color: $gray;
  border-radius: 8px;
  font-size: 16px;
  transition:
    box-shadow 0.4s,
    border-color 0.4s,
    background-color 0.4s;
  &:hover, &:focus, &:active {
    background-color: $blue-smoke;
  }
  &:focus, &:active {
    border-color: $blue;
    box-shadow: inset 0 0 0 1px $blue;
  }
  &.is-invalid {
    border-color: $SCG-red;
    &:focus, &:active {
      box-shadow: inset 0 0 0 1px $SCG-red;
    }
  }
  &-sm {
    height: 36px;
  }
}

.nexter-select {
  .react-select__control {
    height: auto;
    border-color: $gray;
    border-radius: 8px;
    transition: 0.3s;
    .react-select__value-container {
      //font-size: 16px;
    }
    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
    }
    &.react-select__control--is-focused,
    &.react-select__control--menu-is-open {
      box-shadow: inset 0 0 0 1px $blue;
      border-color: $blue;
      background-color: $blue-smoke;
    }
    &.react-select__control--is-disabled {
      border-color: $silver;
      background-color: $white;
      .react-select__placeholder {
        color: $silver;
      }
      .react-select__indicator {
        color: $silver;
      }
    }
    &:hover {
      border-color: $gray;
      background-color: $blue-smoke;
    }
  }
  .react-select__menu {
    z-index: 5;
    border-radius: 8px;
    overflow: hidden;
    .react-select__menu-list {
      margin: 2px 4px;
      .react-select__option--is-focused {
        background-color: transparent;
      }
      .react-select__option:hover {
        background-color: $white-smoke;
      }
      .react-select__option--is-selected {
        color: $white;
        background-color: $SCG-red !important;
      }
    }
  }
  &-sm {
    .react-select__control {
      min-height: 36px;
      height: 36px;
    }
    .react-select__indicator {
      padding: 4px;
    }
  }
}

//Select dropdown font-size and color
.css-1pcexqc-container {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  .css-kj6f9i-menu{
    color: #6f6f6f;
  }
}