.payment-result-container .result-icon {
  font-size: 2.5em;
  border-radius: 50%;
  line-height:  2.5em;
  width: 2.5em;
  height:  2.5em;
  text-align: center;
  display: inline-block;
  border: solid 1px #cccccc;
  color: #cccccc;
}

.payment-result-container .result-icon.success {
  border-color: #28a745;
  color: #28a745;
}

.payment-result-container .result-icon.fail {
  border-color: #dc3545;
  color: #dc3545;
}

.payment-result-container .result-status.success {
  color: #28a745;
}

.payment-result-container .result-status.fail {
  color: #dc3545;
}