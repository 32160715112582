@import '../variables';

.bbl-slip-container {
  background-color: white;
  color: $black;
  padding: 15px;
  .logo {
    padding: 0 23px;
    margin-top: auto;
    margin-bottom: auto;

    img {
      // image responsive
      max-width: 100%;
    }
  }

  .merchant-name {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 23px;
    margin-bottom: 33px;
  }

  .qrcode-container {
    width: fit-content;
    .qrcode-wrapper {
      border: 1px solid $silver;
      padding: 12px;
    }
  }

  .with-left-hr {
    border-left: 1px solid $silver;
  }

  .barcode-container {
    width: 100%;
    padding: 0;
    svg {
      width: 100%;
    }
  }

  .custom-nexter-btn {
    width: 216px;
    margin: 0 16px;
  }

  .custom-nexter-pay-btn {
    width: 216px;
    margin: 0 16px;
    font-weight: bold;
    font-size: 1rem;
  }

  .slip-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    color: $navy-blue;

    &-ref {
      color: #0d46aa;
    }
    &-sm {
      font-size: 16px;
    }
  }

  .slip-list {
    padding: 0 0 0 20px;
    margin-bottom: 0;
  }

  .price {
    font-size: 28px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .remark {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
  }

  .action-container {
    margin-top: 24px;
    font-size: 16px;

    .info-bank {
      padding-bottom: 15px;
      span {
        margin-left: 15px;
      }
    }
  }

  .information-container {
    margin-top: 32px;
    font-size: 16px;

    .info-bank {
      padding-bottom: 15px;
      span {
        margin-left: 15px;
      }
    }
  }
}
