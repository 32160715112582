/***** Wizard *****/

.sw-wizard {
  box-shadow: none !important;
  display: table;
  width: 100%;
  &.nav.nav-tabs {
    border: 0;
    .step-wrapper.nav-item {
      display: table-cell;
      width: auto;
      vertical-align: middle;
      .step.nav-link {
        border: 0;
        padding: 0.5rem 2rem;
        transform: translateY(0) !important;
        text-align: center !important;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 576px) { // sm
          padding: 0.5rem 1rem;
        }

        &:not(.not-before):before,
        &:not(.not-after):after {
          background: $silver !important;
          box-shadow: none !important;
          height: 2px;
          top: 50% !important;
          z-index: -1;
          content: "";
          position: absolute;
          width: 38.5%;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }

        .step-frame-text {
          background-color: $silver;
          border: 2px solid $silver;
          border-radius: 50%;
          // padding: 2px;
        }
        .step-text {
          border-radius: 50%;
          font-size: 0.775rem;
          z-index: 2;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          background: $silver;

          .fas {
            font-size: 0.775rem;
          }
        }
        &.active {
          .step-frame-text {
            border-color: $SCG-red;
          }
          .step-text {
            background-color: $SCG-red;
            border: 2px solid $white;
            color: $white;
          }
        }
        &.done {
          .step-text, .step-frame-text {
            border-color: $SCG-red !important;
            background-color: $SCG-red;
          }
        }
        &.active, &.done {
          &:not(.not-before):before,
          &:not(.not-after):after {
            background: $SCG-red !important;
          }
        }
        &:not(.active) {
          .step-text {
            border-color: $silver;
            color: $white;
          }
        }
      }
      .step-title {
        font-size: 0.575rem;
        max-width: inherit;
      }
    }
  }
}
