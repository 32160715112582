@import './variables';

.list-style-none {
  list-style: none;
}

.custom-hr {
  margin: 0.5rem 0;
  border-top: 1px solid $dim-gray;
}

.custom-hr-2 {
  margin: 0.5rem 0;
  border-top: 1px solid $silver;
}

.payment-wrapper {
  margin-bottom: 3rem;
}

.billpayment-container {
  background: $white-smoke;
  padding: 0.75rem;
  ul,
  ol {
    padding: 0 0 0 15px;
    margin: 0;
  }
}

.alert-payment-status {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid $green-leaf;
  color: $SCG-red;
  background: $white-smoke;
  font-size: 0.875rem;
  &.success,
  &.settled {
    color: $green-leaf;
  }
  &.pending {
    color: $orange;
  }
  .icon {
    font-size: 1.275rem;
  }
}

.alert-static-input {
  color: $black;
  background-color: #f4f4f4;
  border-color: #c6c6c6;
  > label {
    color: #525252;
  }
  > .card-number {
    letter-spacing: 2px;
  }
}

.payment-info-body {
  padding: 1rem 1.8rem 1.8rem 1.8rem;
  font-size: 0.8rem;

  @media (max-width: 576px) {
    // sm
    padding: 1rem 0.8rem 0.8rem 0.8rem;
  }
}
.gradient-header {
  background-image: linear-gradient(to right, #161616 50%, #e20a1a);
}
.font-big {
  font-size: 20px;
  font-weight: 600;
}
.font-medium {
  font-size: 16px;
  font-weight: 600;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-small {
  font-size: 12px;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-black {
  color: $black;
}
.text-red {
  color: $SCG-red;
}
.text-navy-blue {
  color: $navy-blue;
}
.text-blue {
  color: $blue;
}
.text-green {
  color: $green;
}
.text-dark-gray {
  color: $dark-gray;
}

.cart {
  padding: 0.55rem 0;

  &-line {
    border-top: 1px solid $silver;
  }
}

.btn-payment {
  display: block;
  width: 100%;
  user-select: none;
  padding: 0.675rem 0.85rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  background-color: $white;
  border: 1px solid $silver;
  color: $SCG-red;

  &:hover,
  &:focus,
  &.active {
    background-color: $blue-smoke;
  }

  &:focus,
  &.active {
    border: 2px solid $blue;
  }

  &:disabled {
    color: $silver;
  }

  .icon {
    padding: 0 0.875rem;
  }

  .text {
    padding: 0 0.875rem;
  }
}

.btn-installment {
  display: block;
  width: 100%;
  user-select: none;
  padding: 0.675rem 0.85rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  background-color: $white;
  border: 1px solid $gray;
  color: $black;

  .text-plan {
    color: $gray;
    &::after {
      content: '...';
    }
  }

  &:hover,
  &:focus,
  &.focus {
    background-color: $blue-smoke;
  }

  &:focus,
  &.focus {
    border: 2px solid $blue;
    .text-plan {
      color: inherit;
      &::after {
        content: ' *';
      }
    }
  }

  &:disabled {
    color: $silver;
  }

  .icon {
    padding: 0 0.875rem;
  }

  .text {
    padding: 0 0.875rem;
  }
}

// .form-payment {
//   display: block;
//   width: 100%;
//   border: 1px solid $silver;
//   user-select: none;
// }

.form-payment {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: $black;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    .form-payment {
      transition: none;
    }
  }
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  &:hover {
    background-color: $blue-smoke;
  }
  &:focus,
  &:active {
    color: $black;
    background-color: $blue-smoke;
    border: 2px solid $blue;
    outline: 0;
    // box-shadow: 0 0 0 0.2rem rgba(226, 10, 26, 0.25);
  }
  &::placeholder {
    color: $silver;
    opacity: 1;
  }
  &:disabled,
  &[readonly] {
    background-color: $white;
    border-color: $silver;
    color: $silver;
    opacity: 1;
  }
  &:disabled {
    background-color: $white-smoke;
  }
  &-otp {
    font-size: 20px;
    padding: 1.42rem 0.75rem;
  }
}

.promotion-wrapper {
  position: relative !important;

  .btn-slider {
    background: $white;
    color: $dim-gray;
    border: 1px solid $light-silver;
    border-radius: 50%;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    &-left {
      left: -10px;
    }
    &-right {
      right: -10px;
    }
    &:hover {
      background: $SCG-red;
      border-color: $SCG-red;
      color: $white;
    }
  }
}

.promotion-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  white-space: nowrap;
  background: #f4f7fc;
  padding: 20px 15px;
  .promotion-card {
    padding: 15px 10px;
    width: 100%;
    display: flex;
    background: #fff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    cursor: pointer;
    .promotion-detail {
      display: flex;
      flex-direction: column;
      width: 70%;
      box-sizing: border-box;
      padding: 0 15px;
    }
    .name {
      font-size: 16px;
      font-weight: bolder;
      padding-bottom: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .bank {
      font-size: 12px;
      font-weight: bolder;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .description {
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-word;
    }
    :hover .name {
      color: red;
    }
  }
  .item {
    margin: 0 0.15rem;
    display: inline-block;
    white-space: normal;
    width: 30%;
    padding-top: 30%;
    background-size: cover;
    background-position: center;
    // position: relative;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    img {
      float: left;
      width: inherit;
      height: 100%;
      visibility: hidden;
    }

    small {
      float: left;
      padding: 0.2rem;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.payment-result-wrapper {
  color: $black;

  .header {
    color: $SCG-red;
    &.success {
      color: $green;
    }
    &.pending,
    &.expired {
      color: $orange;
    }
    .title {
      color: $black;
      font-size: 20px;
    }
  }
  .icon {
    font-size: 3rem;
  }
  .image {
    width: 48px;
  }
  .description {
    font-size: 16px;
  }
  .btn-custom {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 0.575rem;
  }
}

/*
SELECT
*/

$background-color-button: $light-silver;
$background-color-hover-button: #af0815;
$color-white: #ffffff;
$color-black: #161616;
$color-border-solid: $gray;
$size-height-input: 48px;

.css-bg1rzq-control {
  height: $size-height-input;
  border-radius: 8px;
  border: solid 1px $color-border-solid;
}
.css-1szy77t-control {
  height: $size-height-input;
  border-radius: 8px;
  border-color: $color-border-solid;
  box-shadow: none !important;
}
.css-1wy0on6 {
  .css-bgvzuu-indicatorSeparator {
    display: none;
  }
  .css-16pqwjk-indicatorContainer {
    color: $color-black;
  }
}
.css-kj6f9i-menu {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e0e0e0;
  background-color: $color-white;
  font-size: 12px;
  font-weight: normal;
  color: $black;
  padding: 0.2rem 0;
  .css-dpec0i-option {
    color: $black;
    background-color: $white-smoke;
  }
  .css-xo7z33-option {
    color: $black;
    background-color: $background-color-button;
  }
}
.css-151xaom-placeholder {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: $gray;
}

/** CUSTOM BOOSTRAP MODAL **/

.modal-payment {
  &-content {
    background: transparent;
    border: 0;
  }
  &-header {
    padding: 0;
  }
  &-body {
    background-color: $white;

    img {
      width: 100%;
    }
    .body-inner {
      background-color: $light-silver;
      padding: 0.775rem;
      font-size: 0.775rem;
    }
  }
  &-close {
    background: transparent;
    border: 0;
    color: $white;
    padding: 0;
  }
}

.creditcard-modal {
  &-content {
    border-radius: 0.575rem;
  }
}

.otp-container {
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 575.98px) {
    height: 100vh;
    padding-top: 0px;
    padding-bottom: 0px;
    .main-row {
      height: 100%;
      .card {
        height: 100%;
        border: none;
        border-radius: 0;
        .card-body {
          .description {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.swal2-styled.swal2-confirm {
  background-color: $SCG-red !important;
  border-color: $SCG-red !important;
  box-shadow: none !important;
  &.active,
  &:hover,
  &:focus {
    background-color: $fire-brick !important;
    border-color: $fire-brick !important;
  }
  &.active,
  &:focus,
  &:active {
    border-color: $SCG-red !important;
  }
  &.disabled {
    color: $silver !important;
    background-color: $white-smoke !important;
    border-color: $white-smoke !important;
  }
}

.swal2-title {
  font-size: 20px !important;
  margin-bottom: 15px !important;
  color: $black;
}

.swal2-content {
  font-size: 16px !important;
  color: $black;
}

.option-check-icon {
  color: $silver;
  &.checked {
    color: $SCG-red !important;
  }
}

@media (max-width: 767.98px) {
  .payment-result-page {
    background-color: $white;
    .payment-result-card {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
