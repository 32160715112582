.rrs__label {
  justify-content: center;
  border: 1px solid #999;
}

.rrs__option {
  text-align: center;
}

.rrs__option.rrs__option--next-selection {
  background: #fff;
}

/* @media screen and (max-width: 768px) */
.rrs--options-visible .rrs__options {
  max-height: min-content;
}

/* Payment Logo */
.payment-logo-wrapper {
  width: 32px;
}

.form-group.required .control-label:after {
  content:" *";
  color:red;
}

/* @media screen and (min-width: 768px) {
  .payment-logo-wrapper {
    width: auto;
    max-width: 52px;
  }
} 

@media screen and (min-width: 992px) {
  .payment-logo-wrapper {
    max-width: 65px;
  }
}

@media screen and (min-width: 1200px) {
  .payment-logo-wrapper {
    max-width: 80px;
  }
} */