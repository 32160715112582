@import './variables';

$body-bg: $blue-smoke;
$body-color: $black;

$theme-colors: (
  "primary": $SCG-red,
  "primary-2": $fire-brick,
  "secondary": $light-silver,
  "dark": $black,
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "./fonts";
@import "./button";
@import "./cards";

@import "./nexter-ui/nexter.scss";
@import "./components";

// pages
@import "./payment";