.seller-register-page {
  background-color: #f7f7f7;
  width: 100%;
  min-height: 100vh;
}

.seller-register-container .card-body {
  -webkit-box-shadow: 8px 4px 12px -3px rgba(204,204,204,1);
  -moz-box-shadow: 8px 4px 12px -3px rgba(204,204,204,1);
  box-shadow: 8px 4px 12px -3px rgba(204,204,204,1);
}

.seller-register-container .content .control-label {
  text-align: left;
  font-size: 14px;
  color: '#252422';
}

@media screen and (min-width: 768px) {
  .seller-register-container .content .control-label {
    text-align: right;
  }
} 
