@import './variables';

// @each $color, $value in $theme-colors {
//   .btn-#{$color}:hover {
//     background-color: darken($value, 17%)
//   }
// }

.btn:disabled {
  color: $silver;
  background-color: $white;
  border-color: $silver;
  opacity: 1;
}

.btn-primary {
  color: $white;

  &:hover, &:focus {
    background-color: $fire-brick;
  }

  &:disabled {
    color: $silver;
    background-color: $white-smoke;
    border-color: $white-smoke;
  }
}

.btn-outline-secondary {
  color: $dark-gray;
  border-color: $dark-gray;
}

.btn-outline-success {
  color: $green-leaf;
  border-color: $green-leaf;
}

.btn-secondary {
  color: $dark-gray;

  &:hover, &:focus {
    background-color: $silver;
  }

  &:disabled {
    color: $silver;
    background-color: $white-smoke;
  }
}
